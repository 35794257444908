import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Heartbeat from 'react-heartbeat';
import NotificationAlert from 'react-notification-alert';
import './AlertManager.scss';
import Header from '../../components/Header/Header';
import AlertsTable from '../../components/AlertsTable/AlertsTable';
import {
  fetchMetrics,
  deleteMetric,
  addMetric
} from '../../redux/actions/metricsActions';
import {
  fetchReports
} from '../../redux/actions/catalogsActions'
import { togglePageStatus, clearWidgetCatalog } from '../../redux/actions/viewActions';
import Menu from '../../components/Menu/Menu';
import { HEARTBEAT_INTERVAL_MILLISECONDS } from '../../components/Widgets/utils/catalogs';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  fetchMetrics: () => dispatch(fetchMetrics()),
  fetchReports: () => dispatch(fetchReports()),
  deleteMetric: (id) => dispatch(deleteMetric(id)),
  addMetric: (metric) => dispatch(addMetric(metric)),
  togglePageStatus: (id) => dispatch(togglePageStatus(id)),
  clearWidgetCatalog: () => dispatch(clearWidgetCatalog())
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class Main
 * @extends {Component}
 */
class AlertManager extends Component {
  static propTypes = {
    fetchMetrics: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    togglePageStatus: PropTypes.func.isRequired,
    deleteMetric: PropTypes.func.isRequired,
    addMetric: PropTypes.func.isRequired,
    viewReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    metricsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    alertsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    catalogsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    clearWidgetCatalog: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { viewReducer, clearWidgetCatalog } = this.props;
    clearWidgetCatalog();
    if (!viewReducer.getIn(['view', 'pages', 'alertManager', 'firstLoad'])) {
      this.loadDataSources();
      this.props.togglePageStatus('alertManager');
    }
    const { fetchReports } = this.props;
    if (!viewReducer.getIn(['view', 'pages', 'alertManager', 'firstLoad'])
      || this.props.catalogsReducer.get('reports').isEmpty()) {
      fetchReports();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ]) !== prevProps.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ])
    ) {
      const options = {
        place: 'br',
        message: (
          <div>
            {this.props.viewReducer.getIn([
              'view',
              'pages',
              'main',
              'notifications',
              'message'
            ])}
          </div>
        ),
        type: 'danger',
        icon: 'now-ui-icons ui-1_bell-53',
        autoDismiss: 5
      };
      this.refs.notify.notificationAlert(options);
    }
  }

  loadDataSources = () => {
    const { fetchMetrics } = this.props;
    fetchMetrics();
  };

  render() {
    const metrics = this.props.metricsReducer.get('cards');
    const alerts = this.props.alertsReducer.get('alerts');
    const profile = this.props.userProfileReducer.get('profile');
    const { viewReducer, catalogsReducer } = this.props;
    let emptyCardsMessage = !viewReducer.getIn([
      'view',
      'pages',
      'alertManager',
      'isResourcesReady'
    ]) ? (
      <Row className="Page-emptyAlertsMessage">
        <Col className="Page-loadingMessageContainer">
          <div className="Page-loadingMessage">Loading...</div>
        </Col>
      </Row>
      ) : null;
    emptyCardsMessage = viewReducer.getIn(['view', 'pages', 'alertManager', 'firstLoad'])
      && viewReducer.getIn([
        'view',
        'pages',
        'alertManager',
        'isResourcesReady'
      ])
      && metrics.isEmpty() ? (
        <Row className="Page-emptyAlertsMessage">
          <Col>
            <Jumbotron>
              <h2>
                <i className="fa fa-tachometer Page-IconLarge"></i>
              </h2>
              <p>
                <Link to="/AlertBuilder" className="btn btn-secondary">
                  Add Alert
                </Link>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      ) : (
        emptyCardsMessage
      );
    const staqAppUrl = profile.getIn(['tokens', 'staqAppUrl']);
    const FLUID = true;
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Heartbeat
          heartbeatFunction={this.loadDataSources}
          heartbeatInterval={HEARTBEAT_INTERVAL_MILLISECONDS}
        />
        <Row>
          <Header profile={profile} />
          <NotificationAlert ref="notify" />
        </Row>
        <Menu metrics={metrics} />
        <Row className="Page-Main">
          <Col>
            <Container>
              {emptyCardsMessage}
              <Row>
                <Col align="center">
                  {!metrics.isEmpty() && (
                    <Card className="AlertManager-AlertsTable">
                      <Card.Body>
                        <AlertsTable
                          metrics={metrics}
                          alerts={alerts}
                          deleteMetric={this.props.deleteMetric}
                          addMetric={this.props.addMetric}
                          staqAppUrl={staqAppUrl}
                          widget={catalogsReducer.getIn(['widget', 'widget'])}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertManager);
