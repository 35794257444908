import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import ConfirmationPopover from '../ConfirmationPopover/ConfirmationPopover';
import {
  SMALL_SCREEN_WIDTH,
  OPERATOR_LABELS,
  TOTAL_ALERT_TYPE
} from '../Widgets/utils/catalogs';
import './AlertsTable.scss';

class AlertsTable extends Component {
  static propTypes = {
    alerts: PropTypes.instanceOf(Immutable.List).isRequired,
    metrics: PropTypes.instanceOf(Immutable.List).isRequired,
    staqAppUrl: PropTypes.string.isRequired,
    deleteMetric: PropTypes.func.isRequired,
    addMetric: PropTypes.func.isRequired,
    widget: PropTypes.instanceOf(Immutable.Map)
  };

  static defaultProps = {
    widget: null
  };

  constructor(...args) {
    super(...args);

    this.state = {
      show: false,
      selectedItem: null,
      width: window.innerWidth
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidUpdate() {
    if (this.props.widget && this.props.widget.has('dashboard_id')) {
      const dashboard_id = this.props.widget.get('dashboard_id');
      const staqAppUrl = this.props.staqAppUrl;
      const dasboardUrl = `${staqAppUrl}/dashboards/${dashboard_id}`;
      window.location.href = dasboardUrl;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  onDeleteMetricAction = () => {
    this.setState({ show: false });
    this.props.deleteMetric(this.state.selectedItem.get('id'));
  };

  onDeleteMetric = (metric) => (e) => {
    this.setState({ target: e.target });
    this.setState({ show: !this.state.show, selectedItem: metric });
  };

  onChangeMetric = (id, state) => {
    const { metrics } = this.props;
    const selectedMetric = metrics.find((metric) => metric.get('id') === id);
    this.props.addMetric(selectedMetric.set('enabled', state));
  };

  isMobile = () => {
    const { width } = this.state;
    return width <= SMALL_SCREEN_WIDTH;
  };

  renderActiveBlock(metric) {
    if (metric.get('broken')) {
      return null;
    }
    return metric.get('enabled') ? (
      <ButtonGroup className="mr-2" aria-label="First group">
        <div
          className="AlertsTable-ToggleOn"
          onClick={this.onChangeMetric.bind(null, metric.get('id'), false)}
        >
          <i className="fa fa-toggle-on"></i>
        </div>
      </ButtonGroup>
    ) : (
      <ButtonGroup className="mr-2" aria-label="First group">
        <div
          className="AlertsTable-ToggleOff"
          onClick={this.onChangeMetric.bind(null, metric.get('id'), true)}
        >
          <i className="fa fa-toggle-off"></i>
        </div>
      </ButtonGroup>
    );
  }

  renderActiveBlockMobile(metric) {
    if (metric.get('broken')) {
      return null;
    }
    return metric.get('enabled') ? (
      <ButtonGroup className="mr-2" aria-label="First group">
        <div
          className="AlertsTable-ToggleOn"
          onClick={this.onChangeMetric.bind(null, metric.get('id'), false)}
        >
          <i className="fa fa-toggle-on"></i>
          <div>Active</div>
        </div>
      </ButtonGroup>
    ) : (
      <ButtonGroup className="mr-2" aria-label="First group">
        <div
          className="AlertsTable-ToggleOff"
          onClick={this.onChangeMetric.bind(null, metric.get('id'), true)}
        >
          <i className="fa fa-toggle-off"></i>
          <div>Deactivated</div>
        </div>
      </ButtonGroup>
    );
  }

  renderLastRunBlock(metric) {
    return metric.get('enabled')
      && metric.has('metricLastRun')
      && metric.get('metricLastRun') !== ''
      && metric.get('metricLastRun') !== null ? (
        <Moment format="LLL">{moment.utc(metric.get('metricLastRun'), 'YYYY-MM-DD HH:mm:ss TZD').toDate()}</Moment>
      ) : null;
  }

  renderActionsBlock(metric) {
    const AlertsTableButtonGroupStyle = metric.get('broken')
      ? 'AlertsTable-ButtonGroup-Broken'
      : 'AlertsTable-ButtonGroup';
    return metric.get('enabled') ? (
      <ButtonGroup
        className={`mr-2 ${AlertsTableButtonGroupStyle}`}
        aria-label="Second group"
      >
        <div className="AlertsTable-ActionButton">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip
                id="tooltip-auto"
                style={{ fontSize: 11, opacity: 0.8 }}
              >
                Edit Alert
              </Tooltip>
            )}
          >
            <LinkContainer
              to={{
                pathname: '/AlertBuilder',
                params: { metric }
              }}
            >
              <i className="fa fa-pencil"></i>
            </LinkContainer>
          </OverlayTrigger>
        </div>
        <div
          className="AlertsTable-ActionButton"
          onClick={this.onDeleteMetric(metric)}
        >
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip
                id="tooltip-auto"
                style={{ fontSize: 11, opacity: 0.8 }}
              >
                Delete Alert
              </Tooltip>
            )}
          >
            <i className="fa fa-trash"></i>
          </OverlayTrigger>
        </div>
      </ButtonGroup>
    ) : null;
  }

  renderConditionMobile(metric) {
    const metricFilter = metric.get('filter');
    const metricOperator = metricFilter.first().get('operator');
    const isTwoValuesCondition = metricOperator === 'Between' || metricOperator === 'NotBetween';
    const metricThreshold = metricFilter
      .first()
      .get('values');
    const conditionMessage = isTwoValuesCondition
      ? `${OPERATOR_LABELS[metricOperator]} ${metricThreshold.get(0)} and ${metricThreshold.get(1)}`
      : `${OPERATOR_LABELS[metricOperator]} ${metricThreshold[0]}`;
    const metricLabel = metric.get('metricLabel')
      ? metric.get('metricLabel')
      : metricFilter.first().get('column');
    return metric.get('enabled') ? (
      <div className="AlertsTable-Condition">
        <div style={{ float: 'left' }}>{metricLabel}</div>
        <div style={{ float: 'left', paddingLeft: 3 }}>{conditionMessage}</div>
      </div>
    ) : null;
  }

  renderSourceLink(metric, staqAppUrl) {
    const alertType = metric.get('alert_type');
    if (alertType === TOTAL_ALERT_TYPE) {
      return (
        <a
          className="AlertsTable-ReportLink"
          href={`${staqAppUrl}/dashboards/${metric.getIn(['widget', 'dashboard_id'])}`}
        >
          {metric.getIn(['widget', 'name'])}
          <i className="fa fa-dashboard AlertsTable-ReportIcon"></i>
        </a>
      );
    }
    const filters = metric.get('filter');
    const filtersForURI = escape(encodeURIComponent(JSON.stringify(filters)));
    return (
      <a
        className="AlertsTable-ReportLink"
        href={`${staqAppUrl}/report_viewer/report_views/${metric.get(
          'reportId'
        )}?filters=${filtersForURI}`}
      >
        {metric.get('parentReportName')}
        <i className="fa fa-table AlertsTable-ReportIcon"></i>
      </a>
    );
  }

  renderAlertLink(metric) {
    const metricAlerts = this.props.alerts.filter(
      (alert) => alert.get('metricId') == metric.get('id')
    );
    return !metricAlerts.isEmpty() && metric.get('enabled') ? (
      <Link
        className="AlertsTable-AlertLink"
        to={{
          pathname: `/Alerts/${metric.get('id')}`
        }}
      >
        {metric.get('title')}
      </Link>
    ) : (
      metric.get('title')
    );
  }

  renderMobile = () => {
    const { show, target } = this.state;
    const popover = (
      <ConfirmationPopover
        id="tooltip-trigger-focus"
        body="Deleting this alert is irreversible"
        deleteitem={() => this.onDeleteMetricAction()}
        cancel={() => this.setState({ show: false })}
      />
    );
    const { metrics } = this.props;
    const sortedMetrics = metrics.sortBy((c) => c.get('id'));
    return (
      <Container>
        <Overlay
          target={target}
          show={show}
          placement="left"
          id="popover-trigger-focus"
        >
          {({
            placement,
            scheduleUpdate,
            arrowProps,
            outOfBoundaries,
            show: _show,
            ...props
          }) => <div {...props}>{popover}</div>}
        </Overlay>
        {sortedMetrics.map((metric) => (
          <Row key={metric.get('id')} className="borderedBlock borderedRow">
            <Col xs={12} className="spacedCell">
              <div className="AlertsTable-ReportLink">
                {metric.get('alert_type') === TOTAL_ALERT_TYPE
                  ? metric.getIn(['widget', 'name'])
                  : metric.get('parentReportName')}
              </div>
            </Col>
            <Col xs={12} className="spacedCell">
              {metric.get('title')}
            </Col>
            <Col xs={12} className="spacedCell">
              {this.renderConditionMobile(metric)}
            </Col>
            <Col xs={12} className="spacedCell">
              Last Run
              {this.renderLastRunBlock(metric)}
            </Col>
            <Col xs={6} className="AlertsTable-ToggleContainer">
              {this.renderActiveBlockMobile(metric)}
            </Col>
          </Row>
        ))}
      </Container>
    );
  };

  render() {
    const { show, target } = this.state;
    if (this.isMobile()) {
      return this.renderMobile();
    }
    const popover = (
      <ConfirmationPopover
        id="tooltip-trigger-focus"
        body="Deleting this alert is irreversible"
        deleteitem={() => this.onDeleteMetricAction()}
        cancel={() => this.setState({ show: false })}
      />
    );
    const { metrics, staqAppUrl } = this.props;
    const sortedMetrics = metrics.sortBy((c) => c.get('id'));
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>View Name / Widget Name</th>
            <th>Alert</th>
            <th>Last Run</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <Overlay
            target={target}
            show={show}
            placement="bottom-end"
            id="popover-trigger-focus"
          >
            {({
              placement,
              scheduleUpdate,
              arrowProps,
              outOfBoundaries,
              show: _show,
              ...props
            }) => <div {...props}>{popover}</div>}
          </Overlay>
          {sortedMetrics.map((metric) => (
            <tr key={metric.get('id')}>
              <td>{this.renderSourceLink(metric, staqAppUrl)}</td>
              <td>{this.renderAlertLink(metric)}</td>
              <td>{this.renderLastRunBlock(metric)}</td>
              <td className="AlertsTable-ToggleContainer">
                {this.renderActiveBlock(metric)}
              </td>
              <td>{this.renderActionsBlock(metric)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default AlertsTable;
