import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AlertChip from './components/AlertChip';
import { NUMBER_OF_SIDEBAR_ITEMS_TO_DISPLAY } from '../Widgets/utils/catalogs';
import './AlertsSidePanel.scss';

/**
 * @class AlertsSidePanel
 * @extends {Component}
 */
class AlertsSidePanel extends Component {
  static propTypes = {
    metrics: PropTypes.instanceOf(Immutable.List).isRequired,
    alerts: PropTypes.instanceOf(Immutable.List).isRequired,
    alert_notifications: PropTypes.instanceOf(Immutable.List).isRequired,
    staqAppUrl: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      extra: false
    };
    this.toggleExtra = this.toggleExtra.bind(this);
  }

  toggleExtra() {
    this.setState((prevState) => ({ extra: !prevState.extra }));
  }

  render() {
    const { metrics, alerts, alert_notifications, staqAppUrl } = this.props;
    let newMetrics = new Immutable.List();
    metrics.forEach((metric) => {
      let unreadMetricAlertCount = 0;
      let unreadMetricAlerts = '';
      const metricAlerts = alerts.filter(
        (alert) => alert.get('metricId') === metric.get('id')
      );
      metricAlerts.forEach((metricAlert) => {
        unreadMetricAlerts = alert_notifications.filter(
          (alert_notification) => alert_notification.get('alertId') === metricAlert.get('id')
            && alert_notification.get('unread') === true
        );
        unreadMetricAlertCount += unreadMetricAlerts.size;
      });
      let newMetric = metric;
      if (metricAlerts) {
        newMetric = newMetric.set('metricAlertCount', unreadMetricAlertCount);
      }
      newMetrics = newMetrics.push(newMetric);
    });
    /* eslint-disable */   
    const sortedMetrics = newMetrics.sort((a, b) => {
      if (a.metricAlertCount === b.metricAlertCount) {
        return 0;
      } else if (a.metricAlertCount === undefined) {
        return 1;
      } else if (b.metricAlertCount === undefined) {
        return -1;
      } else {
        return b.metricAlertCount - a.metricAlertCount;
      }
    });
    /* eslint-enable */
    const extraItems = sortedMetrics.size > NUMBER_OF_SIDEBAR_ITEMS_TO_DISPLAY;
    const show = this.state.extra;
    const toggleButton = this.state.extra ? 'Show Less' : 'Show More';
    const toggleArrow = this.state.extra ? (
      <i className="fa fa-chevron-up AlertsSidePanel-Arrow"></i>
    ) : (
      <i className="fa fa-chevron-down AlertsSidePanel-Arrow"></i>
    );
    const emptyMetricsMessage = metrics.size < 1 ? (
      <Row>
        <Col>
          <p className="AlertsSidePanel-NoAlertLabel">
            You haven&apos;t set up any alerts yet..
          </p>
        </Col>
      </Row>
    ) : null;
    return (
      <Container className="AlertsSidePanel-SidebarContainer">
        <Row>
          <Col className="AlertsSidePanel-TitleContainer">
            <div className="AlertsSidePanel-Title">Alerts</div>
          </Col>
          <Col className="AlertsSidePanel-TitleContainer">
            <div className="AlertsSidePanel-Button">
              <Link to="/AlertBuilder" className="btn btn-secondary">
                Add Alert
              </Link>
            </div>
          </Col>
        </Row>
        {emptyMetricsMessage}
        <Row className="scrollable">
          <Col>
            {/* eslint-disable */
            sortedMetrics.map((metric, i) => {
              return i < NUMBER_OF_SIDEBAR_ITEMS_TO_DISPLAY ? (
                <AlertChip key={i} metric={metric} alerts={alerts} staqAppUrl={staqAppUrl} />
              ) : null;
            })
            /* eslint-enable */}
            <Collapse in={show}>
              <div>
                {/* eslint-disable */
                sortedMetrics.map((metric, i) => {
                  return i >= NUMBER_OF_SIDEBAR_ITEMS_TO_DISPLAY ? (
                    <AlertChip key={i} metric={metric} alerts={alerts} staqAppUrl={staqAppUrl} />
                  ) : null;
                })
                /* eslint-enable */}
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col>
            {extraItems && (
              <div className="AlertsSidePanel-ExtraItemsButtonContainer">
                <Button
                  variant="link"
                  onClick={this.toggleExtra}
                  className="AlertsSidePanel-ExtraItemsButton"
                >
                  {toggleButton}
                  {toggleArrow}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AlertsSidePanel;
