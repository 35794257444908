import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import NotificationAlert from 'react-notification-alert';
import Heartbeat from 'react-heartbeat';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import AlertsSidePanel from '../../components/AlertsSidePanel/AlertsSidePanel';
import AlertNotificationCard from '../../components/AlertNotificationCard/AlertNotificationCard';
import {
  markNotificationAsRead,
  fetchAlertNotifications,
  markAllNotificationsAsRead
} from '../../redux/actions/notificationsActions';
import { fetchMetrics, deleteMetric } from '../../redux/actions/metricsActions';
import { togglePageStatus, clearWidgetCatalog } from '../../redux/actions/viewActions';
import { HEARTBEAT_INTERVAL_MILLISECONDS } from '../../components/Widgets/utils/catalogs';
import './Main.scss';
/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  fetchMetrics: () => dispatch(fetchMetrics()),
  fetchAlertNotifications: () => dispatch(fetchAlertNotifications()),
  deleteMetric: (id) => dispatch(deleteMetric(id)),
  togglePageStatus: (id) => dispatch(togglePageStatus(id)),
  markAsRead: (id) => dispatch(markNotificationAsRead(id)),
  markAllNotificationsAsRead: () => dispatch(markAllNotificationsAsRead()),
  clearWidgetCatalog: () => dispatch(clearWidgetCatalog())
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class Main
 * @extends {Component}
 */
class Main extends Component {
  static propTypes = {
    markAsRead: PropTypes.func.isRequired,
    fetchMetrics: PropTypes.func.isRequired,
    fetchAlertNotifications: PropTypes.func.isRequired,
    togglePageStatus: PropTypes.func.isRequired,
    viewReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    alertsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    metricsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    notificationsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    markAllNotificationsAsRead: PropTypes.func.isRequired,
    clearWidgetCatalog: PropTypes.func.isRequired,
    catalogsReducer: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  constructor(...args) {
    super(...args);

    this.state = {
      width: window.innerWidth
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    const { viewReducer, togglePageStatus, clearWidgetCatalog } = this.props;
    clearWidgetCatalog();
    if (!viewReducer.getIn(['view', 'pages', 'main', 'firstLoad'])) {
      this.loadDataSources();
      togglePageStatus('main');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ]) !== prevProps.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ])
    ) {
      const options = {
        place: 'br',
        message: (
          <div>
            {this.props.viewReducer.getIn([
              'view',
              'pages',
              'main',
              'notifications',
              'message'
            ])}
          </div>
        ),
        type: 'danger',
        icon: 'now-ui-icons ui-1_bell-53',
        autoDismiss: 5
      };
      this.refs.notify.notificationAlert(options);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  isMobile = () => {
    const { width } = this.state;
    return width <= 767;
  };

  onMarkNotificationAsRead = (alertNotificationId) => {
    this.props.markAsRead(alertNotificationId);
  };

  onMarkAllNotificationsAsRead = () => {
    this.props.markAllNotificationsAsRead();
  };

  loadDataSources = () => {
    const { fetchMetrics, fetchAlertNotifications } = this.props;
    fetchMetrics();
    fetchAlertNotifications();
  };

  getAlertFromNotification = (alert_notification) => {
    const { userProfileReducer, alertsReducer, metricsReducer } = this.props;
    const profile = userProfileReducer.get('profile');
    const staqAppUrl = profile.getIn(['tokens', 'staqAppUrl']);
    const alerts = alertsReducer.get('alerts');
    const alert = alerts.find(
      (a) => a.get('id') === alert_notification.get('alertId')
    );
    if (!alert) return null;
    let metric = alert.get('alertMetric');
    if (!metric) return null;
    const metrics = metricsReducer.get('cards');
    const ownsMetric = metrics.find((m) => m.get('id') === metric.get('id'));
    if (ownsMetric) {
      metric = metric.set('reportId', ownsMetric.get('reportId'));
    } else {
      const { catalogsReducer } = this.props;
      const sharedMetric = catalogsReducer.get('sharedMetrics').find((m) => m.get('id') === metric.get('id'));
      if (sharedMetric) {
        const sharedMetricReport = catalogsReducer.get('reports').find((r) => r.get('parent_id') === sharedMetric.get('reportId'));
        if (sharedMetricReport) {
          metric = metric.set('reportId', sharedMetricReport.get('id'));
        }
      }
    }
    const duration = 300,
      onExit = (node) => {
        node.style.marginTop = '';
        node.style.marginBottom = '';
      },
      onExiting = (node) => {
        node.style.marginTop = `-${node.offsetHeight}px`;
        node.style.marginBottom = '0px';
      };
    const hideDetailsButton = metric.get('reportId') === undefined;
    return (
      <CSSTransition
        enter={false}
        in={alert_notification.get('unread')}
        key={alert_notification.get('id')}
        timeout={duration}
        classNames="notification"
        onExit={onExit}
        onExiting={onExiting}
      >
        <Col key={alert_notification.get('id')} md={12} xl={12}>
          <AlertNotificationCard
            markAsRead={this.onMarkNotificationAsRead}
            alertNotification={alert_notification}
            alert={alert}
            metric={metric}
            hideDetailsButton={hideDetailsButton}
            staqAppUrl={staqAppUrl}
          />
        </Col>
      </CSSTransition>
    );
  };

  render() {
    let empty_state_message = (
      <Link to="/AlertBuilder" className="btn btn-secondary">
        Add Alert
      </Link>
    );
    if (this.isMobile()) {
      empty_state_message = "You're All Caught Up On Alerts";
    }
    const alerts = this.props.alertsReducer.get('alerts');
    const alert_notifications = this.props.notificationsReducer.get(
      'notificationsObjects'
    );
    const filtered_notifications = alert_notifications.filter(
      (a) => a.get('unread') === true
    );
    const sorted_notifications = filtered_notifications
      .sortBy((c) => c.get('triggered'))
      .reverse();
    const metrics = this.props.metricsReducer.get('cards');
    const metricsEmpty = metrics.size === 0;
    const profile = this.props.userProfileReducer.get('profile');
    const staqAppUrl = profile.getIn(['tokens', 'staqAppUrl']);
    const { viewReducer } = this.props;
    let emptyAlertsMessage = !viewReducer.getIn([
      'view',
      'pages',
      'main',
      'isResourcesReady'
    ]) ? (
      <Row className="Page-emptyAlertsMessage">
        <Col className="Page-loadingMessageContainer">
          <div className="Page-loadingMessage">Loading...</div>
        </Col>
      </Row>
      ) : null;
    emptyAlertsMessage = viewReducer.getIn(['view', 'pages', 'main', 'firstLoad'])
      && viewReducer.getIn(['view', 'pages', 'main', 'isResourcesReady'])
      && sorted_notifications.size < 1 ? (
        <Row className="Page-emptyAlertsMessage">
          <Col>
            <Jumbotron>
              <h2>
                <i className="fa fa-tachometer Page-IconLarge"></i>
              </h2>
              <p>{empty_state_message}</p>
            </Jumbotron>
          </Col>
        </Row>
      ) : (
        emptyAlertsMessage
      );
    const FLUID = true;
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Heartbeat
          heartbeatFunction={this.loadDataSources}
          heartbeatInterval={HEARTBEAT_INTERVAL_MILLISECONDS}
        />
        <Row>
          <Header profile={profile} />
          <NotificationAlert ref="notify" />
        </Row>
        <Menu />
        <Row className="Page-Main">
          {!metricsEmpty && (
            <Col className="Main-AlertsPanel">
              <AlertsSidePanel
                metrics={metrics}
                alerts={alerts}
                alert_notifications={filtered_notifications}
                staqAppUrl={staqAppUrl}
              />
            </Col>
          )}
          <Col>
            <Container>
              {emptyAlertsMessage}
              <Row className="Main-AlertNotificationCardsContainer">
                <Col md={12} xl={12} className="my-auto">
                  {sorted_notifications.size > 0 && (
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <ButtonToolbar style={{ marginRight: 11 }}>
                        <Button
                          size="sm"
                          className="btn btn-secondary"
                          onClick={this.onMarkAllNotificationsAsRead}
                        >
                          Mark All as Read
                        </Button>
                      </ButtonToolbar>
                    </Row>
                  )}
                  <Row>
                    <TransitionGroup
                      component={null}
                      className="items-section__list"
                    >
                      {sorted_notifications
                        .map((alert_notification) => this
                          .getAlertFromNotification(alert_notification))}
                    </TransitionGroup>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
